<template>
  <div class="testimonials-naga">
      <div class="container">
          <div class="testimonials-naga-content">
              <div class="testimonials-naga-content-header">
                  <h3>Here's what our customers have to say</h3>
              </div>


              <div class="testimonials-naga-content-cards">
                  <div class="testimonials-naga-content-card">
                      <div class="testimonials-naga-content-card-img">
                          <img src="../../../assets/lps/naga/male.png" alt="">
                      </div>

                      <div class="testimonials-naga-content-card-content">
                          <p>"I’m an electrician and I knew little about economics or finance. During the lockdowns I decided to learn a new skill that would keep me busy from home. I spent 2 hours a day learning to trade forex and now I do it regularly and confidently.."</p>
                      </div>

                      <div class="testimonials-naga-content-card-qoute">
                          <span>Eric T., 45</span>
                          <span>Electrician</span>
                      </div>
                  </div>

                  <div class="testimonials-naga-content-card">
                      <div class="testimonials-naga-content-card-img">
                          <img src="../../../assets/lps/naga/female.png" alt="">
                      </div>

                      <div class="testimonials-naga-content-card-content">
                          <p>"I’m a single mother, working part-time. I didn’t have money to study trading and investing. My friend told me about the free training Naga markets provides. I learned a lot, for free, and in a very short time I was trading! This was the best thing I could have done for myself and my child."</p>
                      </div>

                      <div class="testimonials-naga-content-card-qoute">
                          <span>Monika G., 38 </span>
                          <span>Secretary</span>
                      </div>
                  </div>
                  
              </div>

             
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .testimonials-naga {
        margin-top: 90px;
    }
    .testimonials-naga-content-header h3 {
        font-size: 38px;
        text-align: center;
        margin-bottom: 70px;
    }
    .testimonials-naga-content-cards {
        display: flex;
        justify-content: center;
    }
    .testimonials-naga-content-card {
        border-radius: 8px;
        position: relative;
        box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.1);
        padding: 20px;
        padding-bottom: 40px;
        text-align: center;
        margin: 20px;
        max-width: 520px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .testimonials-naga-content-card-img {
       position: absolute;
       left: 50%;
       transform: translateX(-50px);
       top: -50px;
    }

    .testimonials-naga-content-card-content {
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .testimonials-naga-content-card-content p {
        font-size: 21px;
    }   

    .testimonials-naga-content-card-qoute {
        margin-top: 30px;
    }

    .testimonials-naga-content-card-qoute span:nth-child(1){
        display: block;
        font-weight: bold;
    }

    @media(max-width:1024px){
       .testimonials-naga-content-header h3 {
        font-size: 26px;
    } 

      .testimonials-naga-content-card-content p {
        font-size: 16px;
    }   

     .testimonials-naga {
        margin-top: 50px;
    }
    }

    @media(max-width:650px){
         .testimonials-naga-content-cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .testimonials-naga-content-card:nth-child(1){
        margin-bottom: 70px;
    }
    }
</style>